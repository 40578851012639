var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.title,
        width: "90%",
        "close-on-click-modal": false
      },
      on: {
        close: function($event) {
          return _vm.handleClose()
        },
        open: function($event) {
          return _vm.handleOpen()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate }
            },
            [_vm._v(" " + _vm._s(_vm.$t("quiz.add")) + " ")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "success" },
              on: { click: _vm.handleUpdateOrder }
            },
            [_vm._v(" " + _vm._s(_vm.$t("quiz.saveOrder")) + " ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "draggableTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "row-key": "id",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap"
                        }
                      },
                      [_vm._v(_vm._s(scope.row.id))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { "min-width": "300px", label: "Title" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.title))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "180px", align: "center", label: "Date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("parseTime")(scope.row.createdAt)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Drag", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  return [
                    _c("svg-icon", {
                      staticClass: "draggable-handler",
                      attrs: { name: "drag", width: "20", height: "20" }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("quiz.actions"),
              align: "center",
              width: "230",
              "class-name": "fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("quiz.edit")) + " ")]
                    ),
                    row.status !== "deleted"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(row, "deleted")
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("quiz.delete")) + " ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("write-quiz", {
        attrs: {
          title: _vm.edit.title,
          visible: _vm.edit.showDialog,
          action: _vm.edit.action,
          quiz: _vm.edit.quiz
        },
        on: {
          "update:visible": function($event) {
            return _vm.$set(_vm.edit, "showDialog", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }