var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { style: { height: _vm.height, zIndex: _vm.zIndex } }, [
    _c(
      "div",
      {
        class: _vm.className,
        style: {
          top: _vm.isSticky ? _vm.stickyTop + "px" : "",
          zIndex: _vm.zIndex,
          position: _vm.position,
          width: _vm.width,
          height: _vm.height
        }
      },
      [_vm._t("default", [_c("div", [_vm._v("sticky")])])],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }