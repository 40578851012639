var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("image-crop-upload", {
    attrs: {
      field: _vm.field,
      width: _vm.width,
      height: _vm.height,
      params: _vm.params,
      headers: _vm.headers,
      "lang-type": _vm.language,
      "with-credentials": true,
      "img-format": "png"
    },
    on: {
      "src-file-set": _vm.srcFileSet,
      "crop-success": _vm.cropSuccess,
      "crop-upload-success": _vm.cropUploadSuccess,
      "crop-upload-fail": _vm.cropUploadFail
    },
    model: {
      value: _vm.show,
      callback: function($$v) {
        _vm.show = $$v
      },
      expression: "show"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }