var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.title,
        width: "90%",
        "close-on-click-modal": false,
        "append-to-body": true
      },
      on: {
        close: function($event) {
          return _vm.handleClose()
        },
        open: function($event) {
          return _vm.handleOpen()
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          staticClass: "form-container",
          attrs: { model: _vm.postForm, rules: _vm.rules }
        },
        [
          _c(
            "sticky",
            { attrs: { "z-index": 10 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "success" },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(" Save ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "createPost-main-container" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: _vm.titleColSpan() } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "40px" },
                          attrs: { prop: "title" }
                        },
                        [
                          _c(
                            "material-input",
                            {
                              attrs: {
                                maxlength: 100,
                                name: "name",
                                required: ""
                              },
                              model: {
                                value: _vm.postForm.title,
                                callback: function($$v) {
                                  _vm.$set(_vm.postForm, "title", $$v)
                                },
                                expression: "postForm.title"
                              }
                            },
                            [_vm._v(" Title ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.postForm.id
                    ? _c(
                        "el-col",
                        { attrs: { span: _vm.titleColSpan() } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "40px" },
                              attrs: { prop: "id" }
                            },
                            [
                              _c(
                                "material-input",
                                {
                                  attrs: {
                                    maxlength: 100,
                                    readonly: "",
                                    name: "id",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.postForm.id,
                                    callback: function($$v) {
                                      _vm.$set(_vm.postForm, "id", $$v)
                                    },
                                    expression: "postForm.id"
                                  }
                                },
                                [_vm._v(" ID ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: _vm.titleColSpan() }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            "margin-bottom": "40px",
                            "padding-top": "50px",
                            "text-align": "right"
                          },
                          attrs: { prop: "onlyAnswer" }
                        },
                        [
                          _vm._v(" Correct answer screen "),
                          _c("el-checkbox", {
                            attrs: { name: "onlyAnswer" },
                            model: {
                              value: _vm.postForm.onlyAnswer,
                              callback: function($$v) {
                                _vm.$set(_vm.postForm, "onlyAnswer", $$v)
                              },
                              expression: "postForm.onlyAnswer"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: _vm.titleColSpan() } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "40px" },
                          attrs: { prop: "quizType", label: "Quiz Type" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "Select",
                                name: "quizType",
                                disabled: _vm.postForm.onlyAnswer
                              },
                              model: {
                                value: _vm.postForm.quizType,
                                callback: function($$v) {
                                  _vm.$set(_vm.postForm, "quizType", $$v)
                                },
                                expression: "postForm.quizType"
                              }
                            },
                            _vm._l(_vm.quizeTypes, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: _vm.titleColSpan() } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "40px" },
                          attrs: { prop: "answerList", label: "Answer" }
                        },
                        [
                          _vm.postForm.quizType === "CHOICE"
                            ? _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "Select",
                                    name: "quizType",
                                    disabled:
                                      _vm.postForm.onlyAnswer ||
                                      _vm.answerString === ""
                                  },
                                  model: {
                                    value: _vm.answerString,
                                    callback: function($$v) {
                                      _vm.answerString = $$v
                                    },
                                    expression: "answerString"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    key: _vm.defaultChoice[0].value,
                                    model: {
                                      value: _vm.defaultChoice[0].value,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.defaultChoice[0],
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression: "defaultChoice[0].value"
                                    }
                                  }),
                                  _c("el-option", {
                                    key: _vm.defaultChoice[1].value,
                                    model: {
                                      value: _vm.defaultChoice[1].value,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.defaultChoice[1],
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression: "defaultChoice[1].value"
                                    }
                                  }),
                                  _c("el-option", {
                                    key: _vm.defaultChoice[2].value,
                                    model: {
                                      value: _vm.defaultChoice[2].value,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.defaultChoice[2],
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression: "defaultChoice[2].value"
                                    }
                                  }),
                                  _c("el-option", {
                                    key: _vm.defaultChoice[3].value,
                                    model: {
                                      value: _vm.defaultChoice[3].value,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.defaultChoice[3],
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression: "defaultChoice[3].value"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "Select",
                                    name: "quizType",
                                    disabled:
                                      _vm.postForm.onlyAnswer ||
                                      _vm.answerString === ""
                                  },
                                  model: {
                                    value: _vm.answerString,
                                    callback: function($$v) {
                                      _vm.answerString = $$v
                                    },
                                    expression: "answerString"
                                  }
                                },
                                _vm._l(_vm.answerTypes, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.postForm.quizType === "CHOICE"
                ? _c(
                    "el-row",
                    { staticStyle: { "padding-bottom": "10px" } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "0 5px" },
                          attrs: { span: 6 }
                        },
                        [
                          _c(
                            "material-input",
                            {
                              attrs: { maxlength: 100, name: "ans1" },
                              model: {
                                value: _vm.defaultChoice[0].value,
                                callback: function($$v) {
                                  _vm.$set(_vm.defaultChoice[0], "value", $$v)
                                },
                                expression: "defaultChoice[0].value"
                              }
                            },
                            [_vm._v(" Answer1 ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "0 5px" },
                          attrs: { span: 6 }
                        },
                        [
                          _c(
                            "material-input",
                            {
                              attrs: { maxlength: 100, name: "ans1" },
                              model: {
                                value: _vm.defaultChoice[1].value,
                                callback: function($$v) {
                                  _vm.$set(_vm.defaultChoice[1], "value", $$v)
                                },
                                expression: "defaultChoice[1].value"
                              }
                            },
                            [_vm._v(" Answer2 ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "0 5px" },
                          attrs: { span: 6 }
                        },
                        [
                          _c(
                            "material-input",
                            {
                              attrs: { maxlength: 100, name: "ans1" },
                              model: {
                                value: _vm.defaultChoice[2].value,
                                callback: function($$v) {
                                  _vm.$set(_vm.defaultChoice[2], "value", $$v)
                                },
                                expression: "defaultChoice[2].value"
                              }
                            },
                            [_vm._v(" Answer3 ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "0 5px" },
                          attrs: { span: 6 }
                        },
                        [
                          _c(
                            "material-input",
                            {
                              attrs: { maxlength: 100, name: "ans1" },
                              model: {
                                value: _vm.defaultChoice[3].value,
                                callback: function($$v) {
                                  _vm.$set(_vm.defaultChoice[3], "value", $$v)
                                },
                                expression: "defaultChoice[3].value"
                              }
                            },
                            [_vm._v(" Answer4 ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  attrs: { prop: "content" }
                },
                [
                  _vm.tinymceActive
                    ? _c("tinymce", {
                        ref: "editor",
                        attrs: { height: 400 },
                        model: {
                          value: _vm.postForm.content,
                          callback: function($$v) {
                            _vm.$set(_vm.postForm, "content", $$v)
                          },
                          expression: "postForm.content"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }