import "core-js/modules/es.object.to-string";
export default function (file) {
  return new Promise(function (resolve) {
    var fileReader = new FileReader();

    fileReader.onload = function () {
      return resolve(fileReader.result);
    };

    fileReader.readAsDataURL(file);
  });
}