import "core-js/modules/es.array.concat";
import request from '@/utils/request';
export var defaultMissionInfoQuizData = {
  missionInfoId: '',
  title: '',
  content: '',
  order: 0,
  onlyAnswer: false,
  quizType: 'OX',
  answerList: []
};
export var getMissionInfoQuizs = function getMissionInfoQuizs(missionInfoId) {
  return request({
    url: "/connect/api/v1/missionInfos/".concat(missionInfoId, "/quizList"),
    method: 'get'
  });
};
export var getMissionInfoQuiz = function getMissionInfoQuiz(missionInfoId, id) {
  return request({
    url: "/connect/api/v1/missionInfos/".concat(missionInfoId, "/quiz/").concat(id),
    method: 'get'
  });
};
export var createMissionInfoQuiz = function createMissionInfoQuiz(data) {
  return request({
    url: "/connect/api/v1/missionInfos/".concat(data.missionInfoId, "/quizList"),
    method: 'post',
    data: data
  });
};
export var updateMissionInfoQuizOrder = function updateMissionInfoQuizOrder(missionInfoId, data) {
  return request({
    url: "/connect/api/v1/missionInfos/".concat(missionInfoId, "/quiz/order"),
    method: 'post',
    data: data
  });
};
export var updateMissionInfoQuiz = function updateMissionInfoQuiz(data) {
  return request({
    url: "/connect/api/v1/missionInfos/".concat(data.missionInfoId, "/quiz/").concat(data.id),
    method: 'put',
    data: data
  });
};
export var deleteMissionInfoQuiz = function deleteMissionInfoQuiz(missionInfoId, id) {
  return request({
    url: "/connect/api/v1/missionInfos/".concat(missionInfoId, "/quiz/").concat(id),
    method: 'delete'
  });
};