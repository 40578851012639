var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.title,
        "close-on-click-modal": false
      },
      on: {
        open: function($event) {
          return _vm.handleOpen()
        },
        close: function($event) {
          return _vm.handleClose()
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticStyle: { width: "80%", "margin-left": "50px" },
          attrs: {
            rules: _vm.formRules,
            model: _vm.formData,
            "label-position": "left",
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mission.type"), prop: "type" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: _vm.$t("mission.type"), clearable: "" },
                  model: {
                    value: _vm.formData.type,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "type", $$v)
                    },
                    expression: "formData.type"
                  }
                },
                _vm._l(_vm.missionTypes, function(item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.label, value: item.key }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("mission.handArmFunction"),
                prop: "handArmFunction"
              }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: {
                    placeholder: _vm.$t("mission.handArmFunction"),
                    clearable: ""
                  },
                  model: {
                    value: _vm.formData.handArmFunction,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "handArmFunction", $$v)
                    },
                    expression: "formData.handArmFunction"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "Select Severity", value: "" }
                  }),
                  _vm._l(_vm.handArmFunctions, function(item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.label, value: item.key }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mission.title"), prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Please input" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("mission.description"),
                prop: "description"
              }
            },
            [
              _c("el-input", {
                attrs: {
                  autosize: { minRows: 2, maxRows: 4 },
                  type: "textarea",
                  placeholder: "Please input"
                },
                model: {
                  value: _vm.formData.description,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "description", $$v)
                  },
                  expression: "formData.description"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mission.day"), prop: "day" } },
            [
              _c("el-input", {
                attrs: { type: "number", placeholder: "Please input" },
                model: {
                  value: _vm.formData.day,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "day", _vm._n($$v))
                  },
                  expression: "formData.day"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mission.image"), prop: "image" } },
            [
              _vm.formData.image
                ? _c("el-image", {
                    staticStyle: { width: "300px", height: "300px" },
                    attrs: { src: _vm.formData.image, fit: "fit" }
                  })
                : _vm._e(),
              _c("image-upload", {
                attrs: { field: "image", width: 300, height: 300 },
                on: {
                  close: _vm.onCloseImageUpload,
                  "src-file-set": _vm.onSrcFileSet,
                  "crop-success": _vm.onCropSuccess,
                  "crop-upload-success": _vm.onCropUploadSuccess,
                  "crop-upload-fail": _vm.onCropUploadFail
                },
                model: {
                  value: _vm.showImageUpload,
                  callback: function($$v) {
                    _vm.showImageUpload = $$v
                  },
                  expression: "showImageUpload"
                }
              }),
              _c("br"),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "upload" },
                  on: { click: _vm.showUploadImage }
                },
                [_vm._v(" Upload Image ")]
              )
            ],
            1
          ),
          _vm.formData.type !== "QUIZ" &&
          _vm.formData.type !== "READING" &&
          _vm.formData.type !== "ACTIVITY"
            ? _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("mission.links"), prop: "linkString" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: "",
                      placeholder: "Please input"
                    },
                    model: {
                      value: _vm.formData.linkString,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "linkString", $$v)
                      },
                      expression: "formData.linkString"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mission.tags"), prop: "tags" } },
            [
              _c("el-input-tag", {
                model: {
                  value: _vm.formData.tags,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "tags", $$v)
                  },
                  expression: "formData.tags"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mission.free"), prop: "free" } },
            [
              _c("el-checkbox", {
                model: {
                  value: _vm.formData.isFree,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "isFree", $$v)
                  },
                  expression: "formData.isFree"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mission.shareUrl"), prop: "shareUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.formData.shareLink,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "shareLink", $$v)
                  },
                  expression: "formData.shareLink"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mission.meta"), prop: "meta" } },
            [
              _c("vue-json-editor", {
                staticStyle: { height: "150px" },
                attrs: {
                  "show-btns": false,
                  mode: "code",
                  modes: ["code", "view"],
                  "exapnded-on-start": true
                },
                on: { "json-change": _vm.onMetaChange },
                model: {
                  value: _vm.meta,
                  callback: function($$v) {
                    _vm.meta = $$v
                  },
                  expression: "meta"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("mission.cancel")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.action === "create" ? _vm.createData() : _vm.updateData()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("mission.confirm")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }